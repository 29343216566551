
import { Component, Vue } from 'vue-property-decorator'
import ButtonComponent from "../ButtonComponent.vue";
import IntersectionObserver from "../IntersectionObserver.vue";

@Component({ components: { ButtonComponent, IntersectionObserver } })
export default class KionOzonSixthBlock extends Vue {

	textButtonSubscription = window.sessionStorage.getItem("buttonSubscription") ? window.sessionStorage.getItem("buttonSubscription") : 'Подключить за 349 ₽/мес'

	handleEnter(val: boolean) {
	const btn = document.querySelector(".btn-block")?.classList;

	if (val) btn?.add("invisible");
	else btn?.remove("invisible");
	}
}

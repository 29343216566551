
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Subscription } from '@/util/promo'
import {tariffPeriodSimpleTranslate, tariffPeriodTranslate} from '@/util/helpers'
import KionButton from '@/components/Shared/Button.vue'
import { PaymentStep } from '@/views/main/components/Subscriptions.vue'
import { httpService, MtsBankWidgetConfig, PaymentSystemEnum, PaymentTypeEnum, RelatedSubscriptionsTextRequest } from '@/util/HttpAdapter'
import {DataLayer, gtm} from "@/util/GTMService";

declare global {
	interface Window {
	MTSPay: any;
	}
}

@Component({ components: { KionButton } })
export default class KionPaymentConfirm extends Vue {
	@Prop({ default: null }) subscription!: Subscription
	@Prop({ default: null }) paymentMethod!: PaymentStep.Card | PaymentStep.Phone
	translate = tariffPeriodTranslate
	translatePromoPeriod = tariffPeriodSimpleTranslate
	getTariff(): string {
		return tariffPeriodTranslate(this.subscription.tariffPeriod)
	}
	processingTransaction = false
	processText = 'Производится оплата...'
	textWithPromoPeriod = `Вы пытаетесь оформить подписку «${this.subscription.name}» за ${this.subscription.discountPrice / 100} ₽ ${this.getTariff()}. Продолжить?`;
	currentPromocode = 'PGNVY';

	textObj = {
		title: "Подтвердить покупку",
		description: `Вы пытаетесь оформить подписку «${this.subscription.name}» за ${this.subscription.discountPrice / 100} ₽ ${this.getTariff()}. Продолжить?`,
		payButtonText: 'Продолжить',
	}

	mounted() {
		if (this.subscription && (this.subscription.discountPrice !== this.subscription.price && this.subscription.tariffPeriod)) {
		this.textObj.description = this.textWithPromoPeriod;
		}
		const data: RelatedSubscriptionsTextRequest = {
			productGid: this.subscription.productId,
			promoCode: this.currentPromocode,
			paymentType: {
				source: this.paymentMethod === PaymentStep.Card ? PaymentTypeEnum.CARD : PaymentTypeEnum.ACCOUNT,
				system: this.paymentMethod === PaymentStep.Card ? PaymentSystemEnum.MTS_MONEY : PaymentSystemEnum.FORIS_ONLINE
			},
		}

		httpService.checkSubscription(data).then(res => {
		console.log(res)
		//  Если есть блокирующие подписки
		if (res.data.blockingProductGids.length || res.data.cancelingProductGids.length) {
		this.textObj.title = res.data.header;
		this.textObj.description = res.data.text;
		this.textObj.payButtonText = res.data.button;

		gtm.pushEvent({
			event: 'subscribe_error',
			event_name: 'subscribe_error',
			price: this.subscription.discountPrice,
			subscription_name: this.subscription.name,
			subscription_id: this.subscription.productId,
			promocode: this.currentPromocode,
			error_message: `User already has blocking product: "${res.data.blockingProductGids.join(', ')}" or cancelling product: "${res.data.cancelingProductGids.join(', ')}"`,
			request_id: null
		})
		}
		});
  }

	onPreviousStep() {
	console.log('onPreviousStep')
	this.$emit('next', PaymentStep.Init)
	}

	pay() {
	this.processingTransaction = true
	if (this.paymentMethod === PaymentStep.Phone) {
		this.payWithPhone()
			.then(el => {
			if (el.status === 200) {
				this.$router.push({path: '/success', query: gtm.getAnalyticsQuery()}).then(() => {
				gtm.pushEvent({
					...this.commonDataLayer,
					payment_type: 'vps',
				})
				})
			} else {
				console.log('Request failed with status code' + el.status)
				gtm.pushEvent({
				event: 'subscribe_error',
				event_name: 'subscribe_error',
				price: this.subscription.price,
				subscription_name: this.subscription.name,
				subscription_id: this.subscription.productId,
				promocode: this.currentPromocode,
				error_message: 'Request failed with status code' + el.status,
				request_id: null
				})
				this.$router.push({path: 'error', query: gtm.getAnalyticsQuery()})
			}
			})
			.catch((e) => {
			console.log(e.message)
			window.sessionStorage.removeItem("request_id")
			gtm.pushEvent({
				event: 'subscribe_error',
				event_name: 'subscribe_error',
				price: this.subscription.price,
				subscription_name: this.subscription.name,
				subscription_id: this.subscription.productId,
				promocode: this.currentPromocode,
				error_message: e.message,
				request_id: null
			})
			this.$router.push({path: 'error', query: gtm.getAnalyticsQuery()})
			})
		return
	}
	if (this.paymentMethod === PaymentStep.Card) {
		this.payWithCard()
		return
	}
	}

	get commonDataLayer(): DataLayer {
	return {
		event: 'subscription_success',
		event_name: 'subscription_success',
		referer: '/subscriptions',
		price: this.subscription.discountPrice,
		subscription_name: this.subscription.name,
		subscription_id: this.subscription.productId,
		promocode: this.currentPromocode,
		deeplink: null,
		request_id: null,
		is_trial: 0,
	}
	}

	payWithPhone() {
	this.processingTransaction = true
	return httpService.payWithPhone(this.subscription)
	}

	async payWithCard() {
	this.processingTransaction = true
	try {
		await this.injectWidget()

		const cfg = await httpService.getWidgetConfig(this.subscription, async (res) => {
		if (!this.processingTransaction) {
			return;
		}
		if (!res.isSuccess && res.eventName === 'failPayment') {
			await this.$router.push({path: '/error', query: gtm.getAnalyticsQuery()})
		} else {
			await this.checkSubscription(this.subscription)
				? this.$router.push({path: '/success', query: gtm.getAnalyticsQuery()}).then(() => {
				gtm.pushEvent({
					...this.commonDataLayer,
					payment_type: 'mts_money',
				})
				}).catch(e => {})
				: await this.$router.push({path: 'error', query: gtm.getAnalyticsQuery()})
		}
		})

		this.setupAndRunWidget(cfg)

	} catch (e) {
		await this.$router.push({path: '/error', query: gtm.getAnalyticsQuery()})
	}
	}

	private injectWidget(fallback = false) {
	return new Promise((resolve, reject) => {
		let widget = document.createElement('script')

		widget.onload = () => {
		console.info('Widget installed')
		resolve('')
		}

		widget.onerror = (err) => {
		if (!fallback) {
			document.head.removeChild(widget)
			console.warn('Widget download failed. Try to install from older version')
			this.injectWidget(true).then(resolve).catch(reject)
		}
		if (fallback) {
			console.error('Widget installation failed.')
			reject(err)
		}
		}

		document.head.appendChild(widget)
		widget.src = fallback ? (process.env.NODE_ENV === 'production' ? '/code' : '') + '/mts-bank-payment-widget.js' : 'https://pay.mts.ru/assets/js/web-sdk/v1/sdk.js'
	})
	}

	private async checkSubscription(s: Subscription): Promise<boolean> {
	this.processText = 'Производится активация промокода...'
	let timeout = 2000
	let subscriptionApplied = false
	for (let i = 0; i < 7; i++) {
		if (i === 0) {
		timeout = 2000
		}
		if (i === 1) {
		timeout = 3000
		}
		if (i > 1) {
		timeout = 5000
		}
		try {
		let res = await this.retry(s, timeout) as any[]
		if (res.length > 0) {
			subscriptionApplied = true
			break
		}
		} catch (e) {
		await this.$router.push({path: '/error', query: gtm.getAnalyticsQuery()})
		}
	}
	return subscriptionApplied
	}

	private async retry(s: Subscription, timer: number) {
	return new Promise((res, rej) => {
		setTimeout(() => {
		httpService.filterAppliedSubscriptions(s)
			.then(el => res(el.data))
			.catch(rej)
		}, timer)
	})
	}

	private setupAndRunWidget(config: MtsBankWidgetConfig) {
	if (window.MTSPay) {
		const widget = new window.MTSPay(config)
		widget.render('bank-payment-widget')
		this.searchButton()
		const shadowRoot = document.querySelector('#bank-payment-widget')?.shadowRoot
		shadowRoot?.addEventListener('click', () => {
		this.searchButton()
		})
	}
	}

	private searchButton() {
	const widgetInterval = setInterval(() => {
		const child = document.querySelector('#bank-payment-widget')?.shadowRoot
		const closeButton = child?.querySelector('.Headerstyled__RightButton-sc-13oivxb-2')
		if (closeButton) {
		closeButton.addEventListener('click', () => {
			this.processingTransaction = false;
			if (child) {
			child.innerHTML = ''
			}
		}, false)
		clearInterval(widgetInterval);
		}
		if (child?.children.length === 0) {
		clearInterval(widgetInterval);
		}
	}, 500)
	}



	goTo(name: 'Offer' | 'Rules') {
	let routeData = this.$router.resolve({ name })
	window.open(routeData.href, '_blank')
	}
}

import Vue from 'vue'
import { httpService } from '@/util/HttpAdapter'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import KionOzon from '@/views/ozon/OzonView.vue'
import AppKionCode from '@/views/AppKionCode.vue'
import KionSuccess from '@/views/main/components/Success.vue'
import KionError from '@/views/main/components/Error.vue'
import KionSsoAuth from '@/views/main/components/SsoAuth.vue'
import KionSubscriptions from '@/views/main/components/Subscriptions.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
	path: '/',
	component: KionOzon,
	children: [
		{
		path: '/ssoauth',
		component: AppKionCode,
		children: [{
			path: '',
			component: KionSsoAuth,
		}]
		},
		{
		path: '/subscriptions',
		component: AppKionCode,
		children: [{
			path: '',
			component: KionSubscriptions,
			beforeEnter: codeGuard
		}]
		},
		{
		path: '/success',
		component: AppKionCode,
		children: [{
			path: '',
			component: KionSuccess,
			beforeEnter: codeGuard
		}]
		},
		{
		path: '/error',
		component: AppKionCode,
		children: [{
			path: '/',
			component: KionError,
			beforeEnter: codeGuard
		}]
		},
	]
	},
]
const router = new VueRouter({
	// scrollBehavior(to, from) {
	// httpService.previousPage = window.location.href
	// if (to.hash) return { selector: to.hash }
	// },
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

function hasQueryParams(route: Route) {
	return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
	httpService.previousPage = window.location.href
	document.querySelector(".main.scrollable")?.scrollTo(0,0)

	if (hasQueryParams(from)) {
		window.sessionStorage.setItem("analyticsQuery", JSON.stringify(from.query))
	}

	if (hasQueryParams(to)) {
		window.sessionStorage.setItem("analyticsQuery", JSON.stringify(to.query))
	}

	next()
})

function codeGuard(to: Route, from: Route, next: NavigationGuardNext) {
	httpService.promoCode ? next() : next('/')
}

export default router


import { Component, Vue } from 'vue-property-decorator'
import FirstBlock from "./components/FirstBlock/FirstBlock.vue";
import SecondBlock from "./components/SecondBlock/SecondBlock.vue";
import ThirdBlock from "./components/ThirdBlock/ThirdBlock.vue";
import FourthBlock from "./components/FourthBlock/FourthBlock.vue";
import FifthBlock from "./components/FifthBlock/FifthBlock.vue";
import SixthBlock from "./components/SixthBlock/SixthBlock.vue";
import SeventhBlock from "./components/SeventhBlock/SeventhBlock.vue";
import EighthBlock from "./components/EighthBlock/EighthBlock.vue";
import TenthBlock from "./components/TenthBlock/TenthBlock.vue";
import PlusBlock from "./components/PlusBlock/PlusBlock.vue";
import KionModal from '@/components/Shared/modal/Modal.vue';
import {gtm} from "@/util/GTMService";

@Component({ components: {
	FirstBlock,
	SecondBlock,
	ThirdBlock,
	FourthBlock,
	FifthBlock,
	SixthBlock,
	SeventhBlock,
	EighthBlock,
	PlusBlock,
	TenthBlock,
	KionModal,
} })
export default class KionOzon extends Vue {
	mounted() {
		gtm.clearSessionSaveOldRequestId(
			window.sessionStorage.getItem("request_id"),
			window.sessionStorage.getItem("subscriptionExist"),
			window.sessionStorage.getItem("buttonSubscription"),
			window.sessionStorage.getItem("analyticsQuery")
		)
	}
}


import { Component, Vue } from 'vue-property-decorator'
import TogglabaleComponent from "../TogglabaleComponent.vue";
import { Swiper } from "swiper";
import IntersectionObserver from "../IntersectionObserver.vue";

@Component({ components: { TogglabaleComponent, IntersectionObserver } })
export default class KionOzonTenthBlock extends Vue {
	mounted() {
	new Swiper(".swiper-3", {
		slidesPerView: "auto",
	});
	}

	animated = false;

	handleEnter(val: boolean) {
	this.animated = val;
	}
}

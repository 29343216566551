
import IntersectionObserver from "../IntersectionObserver.vue";
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { IntersectionObserver } })
export default class KionOzonSecondBlock extends Vue {
	animated = false;

	handleEnter(val: boolean) {
	this.animated = val;
	}
}


import { Component, Vue } from 'vue-property-decorator'
import IntersectionObserver from "../IntersectionObserver.vue";

@Component({ components: { IntersectionObserver } })
export default class KionOzonFooter extends Vue {
	currentYear = new Date().getFullYear();

	handleEnter(val: boolean) {
		const btn = document.querySelector(".btn-block")?.classList;
		console.log(btn, val);
		setTimeout(() => {
			if (val) btn?.add("invisible");
			else btn?.remove("invisible");
		})
	}
}

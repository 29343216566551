import { KION_TARIFF_PERIOD } from '@/util/interfaces'

export function tariffPeriodTranslate(period: KION_TARIFF_PERIOD): string {
	const translate = new Map<KION_TARIFF_PERIOD, string>([
	[KION_TARIFF_PERIOD.DAILY, 'в день'],
	[KION_TARIFF_PERIOD.WEEKLY, 'в неделю'],
	[KION_TARIFF_PERIOD.MONTHLY, 'в месяц'],
	[KION_TARIFF_PERIOD.MULTIMONTH3, 'на 3 месяца'],
	[KION_TARIFF_PERIOD.MULTIMONTH6, 'на 6 месяцев'],
	[KION_TARIFF_PERIOD.ANNUALLY, 'в год'],
	]).get(period)
	return translate || translateMultiPeriod(period)
}

export function tariffPeriodSimpleTranslate(period: KION_TARIFF_PERIOD, timeToLive: number): string {
	const translate = new Map<KION_TARIFF_PERIOD, string>([
	[KION_TARIFF_PERIOD.DAILY, timeToLive + ' день'],
	[KION_TARIFF_PERIOD.WEEKLY, (timeToLive * 7) + ' дней'],
	[KION_TARIFF_PERIOD.MONTHLY, (timeToLive * 30) + ' дней'],
	[KION_TARIFF_PERIOD.ANNUALLY, (timeToLive * 365) + ' дней'],
	]).get(period)
	return translate || translateMultiPeriod(period)
}

export function translateMultiPeriod(period: string): string {
	const count = period.match(/\d+/)
	const type = period.match(/[a-zA-Z]+/)

	if(count === null || type === null) {
	return 'unknown'
	}

	const variants = new Map<string , string[]>([
	['MULTIDAY', ['день', 'дней', 'дня']],
	['MULTIWEEK', ['неделю', 'недель', 'недели']],
	['MULTIMONTH', ['месяц', 'месяцев', 'месяца']],
	]).get(type[0])

	if(!variants) {
	return 'unknown'
	}

	return `в ${count[0]} ${ pluralize(+count[0], variants)}`
}

export function pluralize(count: number, variants: string[]) {
	let length = count % 100
	let result = ''

	if (length >= 11 && length <= 19) {
	result = variants[2]
	} else {
	length %= 10

	if (length === 1) {
		result = variants[0]
	} else if (length === 2 || length === 3 || length === 4) {
		result = variants[1]
	} else {
		result = variants[2]
	}
	}

	return result
}

export function ssoPhoneToMasked(str: string) {
	return `+7` + str.split('').reduce((acc, el, idx) => {
	if (idx === 2) {
		return acc + el + ') '
	}
	if (idx === 5 || idx == 7) {
		return acc + el + '-'
	}
	return acc + el
	}, ' (')
}

const transcriptionRules: { [key: string]: string } = {
	'а': 'a',
	'б': 'b',
	'в': 'v',
	'г': 'g',
	'д': 'd',
	'е': 'e',
	'ё': 'e',
	'ж': 'zh',
	'з': 'z',
	'и': 'i',
	'й': 'i',
	'к': 'k',
	'л': 'l',
	'м': 'm',
	'н': 'n',
	'о': 'o',
	'п': 'p',
	'р': 'r',
	'с': 's',
	'т': 't',
	'у': 'u',
	'ф': 'f',
	'х': 'h',
	'ц': 'c',
	'ч': 'ch',
	'ш': 'sh',
	'щ': 'sch',
	'ъ': '',
	'ы': 'y',
	'ь': '',
	'э': 'e',
	'ю': 'u',
	'я': 'ya',
	' ': '_',
	'': ''
}


export function transcriptRuEn(str: string): string {
	return str
	.toLowerCase()
	.split('')
	.map(char => {
		if ('abcdefghijklmnopqrstuvwxyz1234567890'.indexOf(char) !== -1) {
		return char
		}
		return transcriptionRules[char] ?? ''
	})
	.join('')
}

export function getCookie(name: string): string | undefined {
	const value = `; ${document.cookie}`
	const parts = value.split(`; ${name}=`)
	if (parts.length === 2) return parts.pop()?.split(';').shift()
}

export function getClientID(): string | null {
	return getCookie('_ym_uid') || null
}
export enum KION_ERROR_PAGE_TYPE {
	LIMIT_REACHED = 'limit-reached',
	UNKNOWN_OPERATOR = 'unknown-operator',
	IMPOSSIBLE_TO_CONNECT = 'impossible-to-connect',
	ACCOUNT_NOT_FOUND = 'account-not-found',
	PROMO_OUTDATED = 'promo-outdated',
	SESSION_FAILED = 'session-failed'
}

export enum	KION_PROMO_STATUS {
	OUTDATED = "OUTDATED",
	ACTIVE = "ACTIVE",
	NOT_INVOLVED = "NOT_INVOLVED",
	NOT_FOUND = "NOT_FOUND"
}

export enum KION_TARIFF_PERIOD {
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	MONTHLY = 'MONTHLY',
	ANNUALLY = 'ANNUALLY',
	MULTIMONTH3 = 'MULTIMONTH3',
	MULTIMONTH6 = 'MULTIMONTH6',
}

export enum SHELVES_ITEM_LINK {
	SERIES = "SERIES",
	MOVIE = "MOVIE",
}

export type UNKNOWN_TYPE = string

export interface KionPromoCodeResponse {
 voucher: {
	id: number,
	promoCode: string,
	type: UNKNOWN_TYPE
	redemptionQuantity: number,
	redemptionRemaining: number,
	status: KION_PROMO_STATUS
 },
	promoProductPrices: []
}

export interface KionPromoPrice extends KionPromoPriceFragment{
	productType: string,
	removed: null,
	promotion: {
	promotionId: string,
	promotionName: string,
	campaignId: string,
	campaignName: string,
	campaignStartDate: string,
	campaignEndDate: string,
	discountType: UNKNOWN_TYPE
	discountValue: number,
	timeToLiveType: UNKNOWN_TYPE,
	timeToLiveValue: number
	}
}

export interface KionPromoPriceFragment {
	productId: string,
	price: number,
	discountPrice: number,
	promotion: KionPromoPriceFragmentPromo,
	tariffPeriod:	KION_TARIFF_PERIOD
}

export interface KionPromoPriceFragmentPromo {
	timeToLiveValue: number,
}

import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import MaskedInput from 'vue-text-mask'

@Component({
	components: {
	MaskedInput
	}
})
export default class KionInput extends Vue {
	@Ref('telMask') inputRef!: Vue
	@Prop( { default: 'off' }) autocomplete!: 'off' | 'one-time-code'
	@Prop({ default: '' }) placeholder!: string
	@Prop({ default: null }) errorMsg!: string | null
	@Prop({ default: '_' }) wildcardChar!: string
	@Prop( { default: false }) phone!: boolean
	@Prop( { default: '' }) value!: string;
	@Prop( {default: ''}) name!: string
	mask = ['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/,'-', /\d/, /\d/ ]
	showMask = false
	mounted() {
	if(this.phone) {
		(this.inputRef.$refs.input as HTMLInputElement).setAttribute('type', 'tel')
	}
	}
}

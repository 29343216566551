
import { Component, Vue } from 'vue-property-decorator'
import { Swiper } from "swiper";
import IntersectionObserver from "../IntersectionObserver.vue";

@Component({ components: { IntersectionObserver } })
export default class KionOzonThirdBlock extends Vue {
	mounted() {
	new Swiper(".swiper-1", {
		slidesPerView: "auto",
	});
	}

	animated = false;
	
	handleEnter(val: boolean) {
	this.animated = val;
	}
}

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import {
  KION_TARIFF_PERIOD,
  KionPromoCodeResponse,
  SHELVES_ITEM_LINK,
} from '@/util/interfaces'
import { KION_PICTURE_TYPE, Subscription } from '@/util/promo'
import { WebSsoJWT } from '@/util/HashParser'

interface AuthResponse {
  userId: string
  accessToken: string
  refreshToken: string
  login: string
  password: string
  tokenType: string
  firstLogin: boolean
  expiresIn: number
}

interface Promo {
  promoCode: string
  productId: string
  discountPrice: number
}

export interface RelatedSubscriptionsTextRequest {
	productGid: string;
	promoCode: string;
	paymentType: PaymentType;
}

export interface PaymentType {
	source: PaymentTypeEnum;
	system: PaymentSystemEnum;
}

export const enum PaymentSystemEnum {
	FORIS_ONLINE = 'FORIS_ONLINE',
	MTS_MONEY = 'MTS_MONEY',
}

export const enum PaymentTypeEnum {
	ACCOUNT = 'ACCOUNT',
	CARD = 'CARD',
}

interface CancelingProductGids {
    blockingProductGids: any[];
    button: string;
    cancelingProductGids: any[];
    header: string;
    text: string;
}

export default CancelingProductGids;

export interface MtsBankWidgetResponse {
  isSuccess: boolean
  errorCode?: string
  message?: string
  eventName: 'successPayment' | 'failPayment' | 'validationError'
}

export interface MtsBankWidgetConfig {
  sessionId: string
  scenarioType: 'pay'
  tokenId: string
  environment: 'PROD'
  resultHandler: (resp: MtsBankWidgetResponse) => void
}

export interface KionPostersResponse {
  title: string
  items: KionPostersItemResponse[]
}

export interface KionPostersItemResponse {
  title: string
  imageUrl: string
  link: KionPostersItemResponseLink
}

export interface KionPostersItemResponseLink {
  linkType: SHELVES_ITEM_LINK
  seriesContentId: string
  movieContentId: string
}

class HttpService {
  get grclientid(): string | null {
    return this._grclientid
  }

  set grclientid(value: string | null) {
    this._grclientid = value
  }

  get userId(): string | null {
    return this._userId
  }

  set userId(value: string | null) {
    this._userId = value
  }

  get selectedSubscription(): Subscription {
    return this._selectedSubscription
  }

  set selectedSubscription(val: Subscription) {
	this._selectedSubscription = val
  }

  get webSSOJwt(): WebSsoJWT | { [key: string]: string } | null {
    return this._webSSOJwt
  }

  set webSSOJwt(value: WebSsoJWT | { [key: string]: string } | null) {
    this._webSSOJwt = value
  }

  public subscriptions: Subscription[] = [
	{
		name: "Максимум привилегий с Kion",
		description: "3 в 1: сериалы KION, фильмы, 200+ ТВ-каналов, МТС Premium и OZON Premium",
		ageRating: 12,
		discountPrice: 34900,
		price: 34900,
		promotion: { timeToLiveValue: 1 },
		transcription: '',
		productId: 'kion_base_premium_ozon_s_01_00_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2024',
		tariffPeriod: KION_TARIFF_PERIOD.MONTHLY,
		image: ['promocode-image.jpg', 'promocode-image388.jpg', 'promocode-image320.jpg'],
		isBigPicture: KION_PICTURE_TYPE.BIG,
	},
	{
		name: "Максимум привилегий с Kion",
		description: "3 в 1: сериалы KION, фильмы, 200+ ТВ-каналов, МТС Premium и OZON Premium",
		ageRating: 12,
		discountPrice: 75000,
		price: 104700,
		promotion: { timeToLiveValue: 3 },
		transcription: '',
		productId: 'kion_base_premium_ozon_s_01_00_03_01_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_05_2024',
		tariffPeriod: KION_TARIFF_PERIOD.MULTIMONTH3,
		image: ['promocode-image.jpg', 'promocode-image388.jpg', 'promocode-image320.jpg'],
		isBigPicture: KION_PICTURE_TYPE.BIG,
	},
	{
		name: "Максимум привилегий с Kion",
		description: "3 в 1: сериалы KION, фильмы, 200+ ТВ-каналов, МТС Premium и OZON Premium",
		ageRating: 12,
		discountPrice: 139000,
		price: 209400,
		promotion: { timeToLiveValue: 6 },
		transcription: '',
		productId: 'kion_base_premium_ozon_s_01_00_06_01_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_05_2024',
		tariffPeriod: KION_TARIFF_PERIOD.MULTIMONTH6,
		image: ['promocode-image.jpg', 'promocode-image388.jpg', 'promocode-image320.jpg'],
		isBigPicture: KION_PICTURE_TYPE.BIG,
	},
	{
		name: "Максимум привилегий с Kion",
		description: "3 в 1: сериалы KION, фильмы, 200+ ТВ-каналов, МТС Premium и OZON Premium",
		ageRating: 12,
		discountPrice: 245000,
		price: 418800,
		promotion: { timeToLiveValue: 12 },
		transcription: '',
		productId: 'kion_base_premium_ozon_s_01_00_12_01_bvod_per_year_mts_ott_sdhd4k_b2c_v1_05_2024',
		tariffPeriod: KION_TARIFF_PERIOD.ANNUALLY,
		image: ['promocode-image.jpg', 'promocode-image388.jpg', 'promocode-image320.jpg'],
		isBigPicture: KION_PICTURE_TYPE.BIG,
	},
  ];

  private _selectedSubscription: Subscription = this.subscriptions[0];

  private token: null | string = null
  private _jwtToken = ''
  private _webSSOJwt: null | WebSsoJWT | { [key: string]: string } = null
  private _promoCode = ''
  private _previousPage = ''
  private _phone = ''
  private _userId: null | string = null
  private _grclientid: null | string = null
  private _clientTerminalId = ''
  public promo: Promo = {
    promoCode: '',
    productId: '',
    discountPrice: 0,
  }

  private _http = axios.create({
    baseURL: '/ozkpromo/api',
  })

  private post<T>(
    endpoint: string,
    payload: { [key: string]: unknown } | string = {},
    externalOptions = {}
  ): Promise<AxiosResponse<T>> {
    let options: AxiosRequestConfig = {
      ...externalOptions,
      // withCredentials: true
    }

    if (this.token) {
      options = {
        ...options,
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    }

    return this._http.post<T>(endpoint, payload, options)
  }

  public auth(
    nonce: string,
    webssoCode: string
  ): Promise<AxiosResponse<AuthResponse>> {
    const clientTerminalId = (this.clientTerminalId =
      this.phone + '-' + uuidv4())
    return this.post<AuthResponse>('/ott/actions/websso-login', {
      clientTerminalId,
      terminalType: '2',
      deviceModel: 'Landing',
      deviceName: 'ozon_premium',
      deviceOS: 'Landing KION',
      refreshTokenSupported: false,
      webssoCode,
      nonce,
    })
  }

  public getUserId() {
    const options: AxiosRequestConfig = {
      baseURL: '/ozkpromo/coreapi',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    return this._http.get<{ huaweiSubscriberId: string }>(
      '/subscribers/me',
      options
    )
  }

  public checkSubscription(data: RelatedSubscriptionsTextRequest) {
    const options: AxiosRequestConfig = {
      baseURL: '/ozkpromo/tvh-subscriptions',
      headers: {
        'Authorization': `Bearer ${this.token}`
      }
    }
    return this._http.post<CancelingProductGids>('/subscription/related-subscriptions-text', data, options)
  }

  public isMtsAccount() {
    const options: AxiosRequestConfig = {
      baseURL: '/ozkpromo/privateapi',
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    return this._http.get<{ saleBlock: null | string }>('/account/me', options)
  }

  public payWithPhone({ productId, discountPrice }: Subscription) {
    const options: AxiosRequestConfig = {
      baseURL: '/ozkpromo/subscriptionapi',
    }
    const payload = {
      productId,
      price: discountPrice,
      programId: null,
      promoCode: this.promoCode,
      clientTerminalId: this.clientTerminalId,
    }
    return this.post('/subscriptions', payload, options)
  }

  public async getWidgetConfig(
    subscription: Subscription,
    callback: (resp: MtsBankWidgetResponse) => void
  ): Promise<MtsBankWidgetConfig> {
    // const { webssoSessionToken } = (await this.getPaymentToken()).data
    const { sessionId } = (await this.getPaymentSession(subscription)).data
    return new Promise<MtsBankWidgetConfig>((res) =>
      res({
        sessionId, //sessionId,
        scenarioType: 'pay',
        tokenId: this.jwtToken,
        environment: 'PROD',
        resultHandler: callback,
      })
    )
  }

  private getPaymentSession({ productId, discountPrice }: Subscription) {
    const options: AxiosRequestConfig = {
      baseURL: '/ozkpromo/cardapi',
    }

    const payload: { [key: string]: null | string | number | boolean } = {
      productId,
      price: discountPrice,
      programId: null,
      marketingType:
        this.promoCode.toLowerCase() !== 'kionmir' ? 'LANDING' : 'MIR_CASHBACK',
      promocode: 'PGNVY',
      support3ds: true,
    }

    return this.post<{ sessionId: string }>(
      '/payment/create-session',
      payload,
      options
    )
  }

  // private getPaymentToken() {
  //	const options: AxiosRequestConfig = {
  //	baseURL: process.env.NODE_ENV === 'development' ? '/privateapi' : 'https://prod.tvh.mts.ru/tvh-api-gateway/rest'
  //	}
  //
  //	return this.post<{ webssoSessionToken: string }>('/general/actions/get-websso-session-token', {}, options)
  // }

  public getLastOfferMarkup() {
    const options: AxiosRequestConfig = {
      baseURL: '/ozkpromo/offertapi',
    }
    return this.post('/offers/actions/get-last-published-offer', {}, options)
  }

  public getSubscriptionList() {
    const { promoCode } = this.promo
    const options: AxiosRequestConfig = {
      baseURL: '/ozkpromo/subscriptionapi',
    }
    return this.post<KionPromoCodeResponse>(
      '/products/actions/get-promo-products',
      { promoCode },
      options
    )
  }

  public filterAppliedSubscriptions({ productId }: Subscription) {
    const options: AxiosRequestConfig = {
      baseURL: '/ozkpromo/subscriptionapi',
    }

    return this.post<KionPromoCodeResponse[]>(
      '/subscriptions/actions/get-subscriptions-by-filter',
      { productId },
      options
    )
  }

  public getImgsForSwapper() {
    const config: AxiosRequestConfig = {
      headers: {
        'X-Device-Model': 'AndroidPhone_Widevine_VMX_v3',
        'X-Device-Id': 'premium_kion_ru',
        'X-App-Version': '1',
      },
    }

    return axios.get<KionPostersResponse>('/shelves/glo_shelf_cm_1972', config)
  }

  get phone() {
    return this._phone
  }

  set phone(phone: string) {
    this._phone = phone
  }

  get jwtToken() {
    return this._jwtToken
  }

  set jwtToken(str: string) {
    this._jwtToken = str
  }

  get clientTerminalId(): string {
    return this._clientTerminalId
  }

  set clientTerminalId(value: string) {
    this._clientTerminalId = value
  }

  public set promoCode(str: string) {
    this.promo.promoCode = str.toUpperCase()
    this._promoCode = str.toUpperCase()
  }

  public get promoCode(): string {
    return this._promoCode
  }

  public set previousPage(str: string) {
    this._previousPage = str
  }

  public get previousPage(): string {
    return this._previousPage
  }

  public set accessToken(str: string) {
    this.token = str
  }
}

export const httpService = new HttpService()

<template>
  <div class="root">
   <HeaderComponent />
   <router-view />
   <FooterComponent />

   <portal-target name="modal" />
   <div id="bank-payment-widget" />
  </div>
</template>

<script>
import { gtm } from "@/util/GTMService";
import { Component, Vue } from 'vue-property-decorator'
import WOW from "wow.js";
import HeaderComponent from "./views/ozon/components/HeaderComponent/HeaderComponent.vue";
import FooterComponent from "./views/ozon/components/FooterComponent/FooterComponent.vue";
import {getClientID} from "@/util/helpers";

@Component({ components: {
	HeaderComponent,
	FooterComponent,
}})
export default class App extends Vue {

	mounted() {
	gtm.clearSessionSaveOldRequestId(
		window.sessionStorage.getItem("request_id"),
		window.sessionStorage.getItem("subscriptionExist"),
		window.sessionStorage.getItem("buttonSubscription"),
		window.sessionStorage.getItem("analyticsQuery")
	)
	const intervalId = setInterval(() => {
		if (getClientID()) {
		clearInterval(intervalId)
		gtm.pushEvent({
			event_name: 'screen_show'
		})
		}
	}, 500)

	new WOW().init();

	window.addEventListener("scroll", () => {
		const scrolled = window.scrollY;
		const btn = document.querySelector(".btn-block")?.classList;

		if (!btn) return;

		if (scrolled >= 50) btn.add("visi-mobile");
		else btn.remove("visi-mobile");

		if (scrolled >= window.innerHeight - 300) btn.add("visi-desktop");
		else btn.remove("visi-desktop");
	});
	}
}
</script>

<style lang="scss">
@use "styles/typography" with ( $path: "~@/assets/fonts/" );
@use "styles/colors" as *;
@use "styles/grid" as *;

.root {
	@extend %palette;
}

.overlay .modal {
	background-color: #284059;
}
</style>

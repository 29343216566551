import { render, staticRenderFns } from "./FourthBlock.vue?vue&type=template&id=70dab10d"
import script from "./FourthBlock.vue?vue&type=script&lang=ts&setup=true"
export * from "./FourthBlock.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./FourthBlock.vue?vue&type=style&index=0&id=70dab10d&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
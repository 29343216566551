
import KionTemplate from '@/components/Shared/Template.vue'
import KionButton from '@/components/Shared/Button.vue'
import { httpService } from '@/util/HttpAdapter'
import { Component, Vue } from 'vue-property-decorator'
import {gtm} from "@/util/GTMService";

@Component({
	components: {
	KionButton,
	KionTemplate
	}
})
export default class KionSuccess extends Vue {
	code: null | string = httpService.promoCode

	mounted() { }

	moveTo(link: string) {
	window.location.assign(link)
	}
}

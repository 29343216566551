
import {gtm} from "@/util/GTMService";
import {Component, Vue} from 'vue-property-decorator'
import KionTemplate from '@/components/Shared/Template.vue'
import Template from '@/components/Shared/Template.vue'
import {KION_PICTURE_TYPE, Subscription} from '@/util/promo'
import KionCard from '@/components/Shared/Cards/Card.vue'
import KionSmallCard from '@/components/Shared/Cards/SmallCard.vue'
import {httpService} from '@/util/HttpAdapter'
import KionPaymentInit from '@/views/main/components/modals/PaymentInit.vue'
import KionPaymentConfirm from '@/views/main/components/modals/PaymentConfirm.vue'
import KionButton from '@/components/Shared/Button.vue'
import KionInput from '@/components/Shared/Input.vue'
import { KION_TARIFF_PERIOD } from '@/util/interfaces'

export enum PaymentStep {
	Init,
	Phone,
	Card,
	Error,
	Success
}


@Component({
	components: {
	Template,
	KionTemplate,
	KionCard,
	KionSmallCard,
	KionPaymentInit,
	KionPaymentConfirm,
	KionButton,
	KionInput
	}
})
export default class KionSubscriptions extends Vue {
	loading = false
	allSubscriptions: Subscription[] = httpService.subscriptions
	selectedSubscription: Subscription | null = null
	subscriptionExist = !!window.sessionStorage.getItem("subscriptionExist")
	code = null
	step = PaymentStep.Init
	PaymentStep = PaymentStep

	isSelectedPaymentMethod() {
	return [PaymentStep.Phone, PaymentStep.Card].indexOf(this.step) !== -1
	}

	onPreviousStep() {
	this.$router.push('/')
	}

}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import PortalVue from 'portal-vue'
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false
Vue.use(PortalVue)
Vue.use(VueLazyload, {preLoad: 1.5});

new Vue({
	router,
	render: (h) => h(App)
}).$mount('#app')



import KionModal from '@/components/Shared/modal/Modal.vue';
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: {
	KionModal,
} })
export default class AppKionCode extends Vue {
	mounted() {
	document.querySelector('html')?.classList.add('overflow-hidden')
	}

	closeModal() {
	console.log('logclose');
	this.$router.push('/')
	}

	destroyed() {
	document.querySelector('html')?.classList.remove('overflow-hidden')
	}
}

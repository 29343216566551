
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Subscription } from '@/util/promo'
import { tariffPeriodTranslate } from '@/util/helpers'
import KionButton from "@/components/Shared/Button.vue";

@Component({
	components: { KionButton}
})
export default class KionCard extends Vue {
	@Prop({ default: null }) subscription!: Subscription
	@Prop( {default: true}) showButton!: boolean

	getTariff(): string {
	return tariffPeriodTranslate(this.subscription.tariffPeriod)
	}

	onButtonClick() {
	this.$emit('select', this.subscription)
	}

	transformPrice(price: number) {
	return price / 100
	}

	getBackgroundImage() {
	if(this.subscription.image === null) {
		return null
	}
	const images = require.context('/src/assets/promo-images/', false, /\.(png|jpg)$/)
	let currentImageSize;
	if (window.innerWidth < 320) {
		currentImageSize = this.subscription.image[2]
	} else if (window.innerWidth >= 320 && window.innerWidth <= 388) {
		currentImageSize = this.subscription.image[1]
	} else {
		currentImageSize = this.subscription.image[0]
	}
	return images('./' + currentImageSize)
	}
}


import {DataLayer, gtm} from "@/util/GTMService";
import { Component, Vue } from 'vue-property-decorator'
import KionTemplate from '@/components/Shared/Template.vue'
import { parseJWT, parseUriHash } from '@/util/HashParser'
import { httpService } from '@/util/HttpAdapter'

@Component({ components: { KionTemplate } })
export default class KionSsoAuth extends Vue {
	async mounted() {
	if (Object.keys(this.$route.query).length === 0 && !this.$route.hash) {
		this.$router.push('/')
	}
	const { state, code, id_token } = parseUriHash(this.$route.hash)
	httpService.jwtToken = id_token
	const { phone, nonce, sub } = httpService.webSSOJwt = parseJWT(id_token)
	httpService.promoCode = state
	httpService.phone = phone
	try {
		console.log('try to auth');
		const { accessToken, firstLogin } = (await httpService.auth(nonce, code)).data
		httpService.accessToken = accessToken
		const { huaweiSubscriberId } = (await httpService.getUserId()).data
		httpService.userId = sub
		httpService.grclientid = huaweiSubscriberId

		const statusPromotion = (await httpService.filterAppliedSubscriptions(httpService.selectedSubscription)).data

		if (statusPromotion.length !== 0) {
		window.sessionStorage.setItem("subscriptionExist", "1")
		window.sessionStorage.setItem("buttonSubscription", "Вы уже подписаны")
		}

		this.$router.push({path: '/subscriptions', query: gtm.getAnalyticsQuery()}).then(() => {
		const gtmCommonData: DataLayer = {
			auth_method: "sms",
			auth_type: 'websso',
		}

		if (firstLogin) {
			gtm.pushEvent({
			event: "registration",
			event_name: "registration",
			request_id: null,
			...gtmCommonData,
			})
			gtm.pushEvent({
			event: 'authorization_success',
			event_name: 'authorization_success',
			request_id: sessionStorage.getItem("request_id"),
			...gtmCommonData,
			})
		} else {
			gtm.pushEvent({
			event: 'authorization_success',
			event_name: 'authorization_success',
			request_id: sessionStorage.getItem("request_id"),
			...gtmCommonData,
			})
		}
		})
	} catch (e) {
		await this.$router.push({path: '/error', query: gtm.getAnalyticsQuery()})
	}
	}
}

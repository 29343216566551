
import { Component, Vue } from 'vue-property-decorator'
import ButtonComponent from "../ButtonComponent.vue";

@Component({ components: { ButtonComponent } })
export default class KionOzonFirstBlock extends Vue {

	textButtonSubscription = window.sessionStorage.getItem("buttonSubscription") ? window.sessionStorage.getItem("buttonSubscription") : 'Подключить за 349 ₽/мес'

	scrollToKion() {
		var container = document.querySelector("#podpiska-kion");
		console.log(container)
		if (container) {
			container.scrollIntoView();
		}
	}
}

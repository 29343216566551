
import { Component, Vue } from 'vue-property-decorator'
import ButtonComponent from "../ButtonComponent.vue";

@Component({ components: { ButtonComponent } })
export default class KionOzonHeader extends Vue {
	hash = location.hash;
	textButtonSubscription = window.sessionStorage.getItem("buttonSubscription") ? window.sessionStorage.getItem("buttonSubscription") : 'Подключить за 349 ₽/мес'

	updateCurrentHash() {
	setTimeout(() => {
		this.hash = window.location.hash;
	});
	}
}
